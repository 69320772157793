import React, {useEffect, useReducer, useState} from "react";
import {getProp, onDocumentPrint} from "../../../../common/util/util-helpers";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {formatMoney, getUser} from "../../../../util/util";
import Resources from "../../../../data/services/resources";
import {getResource, updateResource} from "../../../../data/actions/resource";
import {useSelector} from "react-redux";
import {genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import LocalStorage from "../../../../util/localStorage";
import {numberWithCommasToBack} from "../../../../util/util-formaters";
import WorkOrdersLaborPartsPrices from "./work-orders-labor-parts-prices";
import {fieldsToHtml, fillFieldsFromData} from "../../../../common/util/util-fields";
import Card from "../../../../common/components/card";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import {BoltIcon, PencilIcon, TrashIcon, XCircleIcon} from "@heroicons/react/24/outline";
import Buttons from "../../../../common/components/buttons";
import PageTabWithFooter from "../../../../common/components/layout/layout-components/page/page-tab-with-footer";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import Button from "../../../../common/components/button";
import InfoParagraph from "../../../../common/components/info-paragraph";
import ModalDefault from "../../../../common/components/modal/modal-default";
import FileViewer from "../../../../common/components/file-viewer/components";
import Env from "../../../../util/env";
import {getJWT} from "../../../../common/util/util-auth";
import {download} from "../../../../data/actions/download";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../common/util/util-consts";
import axios from "axios";
import WorkOrdersLaborPartsAiModal from "./work-orders-labor-parts-ai-modal";

export default function WorkOrdersLaborPartsTab({dispatch, ID, translate, isInvoiced}) {

    /** Constants
     ================================================================= */
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);

    let data = getProp(resource, "data.Items", [])
    const dataObject = getProp(resource, "data", [])
    const dataSettings = getProp(resource, "data.Settings", [])
    const dataMisc = getProp(resource, "data.Misc", [])
    const dataUnit = getProp(resource, "data.Unit", {
        Type: "Truck",
        Year: "Unspecified",
        Manufacturer: "Unspecified",
        Model: "Unspecified",
    })

    const dataParts = data.map(item => {
        item['Parts'].map(it => {
            it.TotalAmount = numberWithCommasToBack(it.PartPrice) * (Number(it.PartQty) ?? 0) * (Number(it.TaxAmount ?? 1));
            return it;
        })

        return item['Parts']
    });

    const totalPriceParts = dataParts.reduce((memo, it) => {
        memo += it.reduce((partMemo, part) => {
            partMemo += part.TotalAmount

            return partMemo
        }, 0)

        return memo;
    }, 0);

    const dataLabor = data.map(item => {
        item['Labor'].map(it => {
            it.TotalAmount = numberWithCommasToBack(it.LaborPrice) * (Number(it.LaborHours) ?? 0) * (Number(it.TaxAmount ?? 1));
            return it;
        });

        return item['Labor']
    })

    const totalPriceLabor = dataLabor.reduce((memo, it) => {
        memo += it.reduce((laborMemo, labor) => {
            laborMemo += labor.TotalAmount

            return laborMemo
        }, 0)
        return memo;
    }, 0);

    const dataMiscTotal = dataMisc.reduce((memo, it) => {
        memo += it.TotalAmount

        return memo
    }, 0)

    /** State
     ================================================================= */
    const [fieldsAction, setFieldsAction] = useState([]);
    const [initialComplaintFields, setInitialComplaintFields] = useState(getInitialComplaintFields(dataObject, isInvoiced));

    const [isPartsFromDialogOpen, setIsPartsFormDialogOpen] = useState(false);
    const [isLaborFromDialogOpen, setIsLaborFormDialogOpen] = useState(false);
    const [isMiscFormDialogOpen, setIsMiscFormDialogOpen] = useState(false);
    const [previewDialog, setPreviewDialog] = useState(false);
    const [aiData, setAiData] = useState(undefined);
    const [aiDialog, setAiDialog] = useState(false);
    const [aiLoading, setAILoading] = useState(false);
    const [previewDocument, setPreviewDocument] = useState(undefined);
    const [selectedItem, setSelectedItem] = useState(undefined)
    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [partCreateIndex, setPartCreateIndex] = useState(null);
    const [laborCreateIndex, setLaborCreateIndex] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [additionalAiInfo, setAdditionalAiInfo] = useState("");

    const handleInputChange = (name, value, index, fields) => {
        setFieldsAction(prevState => ({
            ...prevState,
            [index]: FieldsManager.updateField(fields, name, value)
        }))
        setIsDirty(true);
    }
    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: getUser(),
            resource: Resources.WorkOrderParts,
            query: {WorkOrderID: ID}
        }))
    }

    const handleDownloadDocument = () => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.WorkOrderEstimate,
            query: Object.assign({}, {
                WorkOrderID: ID,
                status: 1,
                type: 1,
                name: 'estimate_' + ID + '.pdf'
            }),
            errorMessage: true, successMessage: 'Successfully downloaded estimate!'
        }))
    }

    const handleConfirmDeletePartClick = (item) => {

        let newData = data;
        newData[item.ind]['Parts'].splice(item.dataIndex, 1);

        if (item.ChildPartID !== null) {
            newData[item.ind]['Parts'] = newData[item.ind]['Parts'].filter((it) => it.ParentPartID !== item.ChildPartID)
        }

        closeConfirmDialog();
        setIsDirty(true);
    }

    const handleConfirmDeleteLaborClick = (item) => {

        let newData = data;
        newData[item.ind]['Labor'].splice(item.dataIndex, 1);

        closeConfirmDialog();
        setIsDirty(true);
    }

    const handleConfirmDeleteMiscClick = (item) => {

        let newData = dataMisc;
        newData.splice(item.dataIndex, 1);

        closeConfirmDialog();
        setIsDirty(true);
    }

    const handleCancelClick = () => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmCancelClick(),
            type: "danger",
            content:
                `Are you sure you want to discard all changes?`
            ,
            title: translate("Delete unsaved changes"),
            buttonLabel: translate("btn.delete")
        })
    }
    const handleConfirmCancelClick = () => {

        fetchData()

        closeConfirmDialog();

        setIsDirty(false);
    }

    /** UI Events
     ================================================================= */
    const handleCreateLaborClick = (index) => {
        setIsLaborFormDialogOpen(true);
        setSelectedItem(undefined);
        setLaborCreateIndex(index)
    }

    const handleCreatePartClick = (index) => {
        setIsPartsFormDialogOpen(true);
        setSelectedItem(undefined);
        setPartCreateIndex(index)
    }

    const handleEditPartClick = (item) => {
        setIsPartsFormDialogOpen(true);
        setSelectedItem(item);

    }

    const handleEditLaborClick = (item) => {
        setIsLaborFormDialogOpen(true);
        setSelectedItem(item);
    }

    const handleCreateMiscClick = (index) => {
        setIsMiscFormDialogOpen(true);
        setSelectedItem(undefined);
        // setLaborCreateIndex(index)
    }

    const handleEditMiscClick = (item) => {
        setIsMiscFormDialogOpen(true);
        setSelectedItem(item);
    }

    const handleInitialComplaintInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(initialComplaintFields, name, value)
        setInitialComplaintFields(newFields)
        setIsDirty(true)
    }

    const handleLaborInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value);

        if (name === "TaxID") {
            fields.Tax.value = value.label
        }

        fields.TaxAmount.value = fields.TaxID?.value?.amount ?? fields.TaxAmount.value

        fields.TotalAmount.value = formatMoney((numberWithCommasToBack(fields.LaborPrice.value) * Number(fields.LaborHours.value) ?? 0) * (Number(fields.TaxAmount.value) ?? 1).toString());

        return fields;
    }

    const handleMiscInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value);

        if (name === "TaxID") {
            fields.Tax.value = value.label
        }

        fields.TaxAmount.value = fields.TaxID?.value?.amount ?? fields.TaxAmount.value

        fields.TotalAmount.value = formatMoney((numberWithCommasToBack(fields.MiscPrice.value) * Number(fields.MiscQty.value) ?? 0) * (Number(fields.TaxAmount.value) ?? 1).toString());

        return fields;
    }

    const handlePartsInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value);

        if (name === "TaxID") {
            fields.Tax.value = value.label
        }

        fields.TaxAmount.value = fields.TaxID?.value?.amount ?? fields.TaxAmount.value

        fields.TotalAmount.value = formatMoney((numberWithCommasToBack(fields.PartPrice.value) * Number(fields.PartQty.value) ?? 0) * (Number(fields.TaxAmount.value) ?? 1).toString());

        if (name === "PartName" && value) {
            fields.ProductServiceID.value = value.value


            if (value.DirtyProductServiceID) {
                fields.DirtyProductServiceID.value = value.DirtyProductServiceID
            }

            if (value.InherentProductServiceID) {
                fields.InherentProductServiceID.value = value.InherentProductServiceID
            }

            if (value.DirtyProductService) {
                fields.DirtyProductService.value = value.DirtyProductService
            }

            if (value.InherentProductService) {
                fields.InherentProductService.value = value.InherentProductService
            }
        }
        return fields;
    }

    const handleDeletePartClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeletePartClick(item),
            type: "danger",
            content:
                `Are you sure you want to delete item ${item.PartName}?`
            ,
            title: translate("text.confirm_delete_part"),
            buttonLabel: translate("btn.delete")
        })
    }


    const handleDeleteLaborClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteLaborClick(item),
            type: "danger",
            content:
                `Are you sure you want to delete item ${item.Description}?`
            ,
            title: translate("text.confirm_delete_labor"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleDeleteMiscClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteMiscClick(item),
            type: "danger",
            content:
                `Are you sure you want to delete item ${item.Description}?`
            ,
            title: translate("text.confirm_delete_misc_item"),
            buttonLabel: translate("btn.delete")
        })
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    const scrollErrorIntoViewItems = (fields, i) => {
        for (const [key, value] of Object.entries(fields)) {
            if (value.errorMessage) {
                const firstErrorInput = document.getElementById(`itemIndex${i}`);

                if (firstErrorInput) {
                    firstErrorInput.parentElement.scrollIntoView({block: "center", behavior: "instant"});
                    return true;
                }
            }
        }

        return false;
    }
    const scrollErrorIntoViewFieldsItems = (array) => {
        for (let i = 0; i < array.length; i++) {
            scrollErrorIntoViewItems(array[i], i)
        }

        return false;
    }

    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const submit = () => {
        let dataItems = {};
        let dataForSubmit = {};

        data.map((item, itemIndex) => {
            let actionItems = Object.values(fieldsAction[itemIndex]);

            let newObject = {};

            newObject[itemIndex] = {
                ActionItem: null,
                ActionItemStatusID: null,
                AssignedContactID: null,
                CustomCodeID: null,
                ServiceBayID: null,
                ActionItemDescription: null
            }

            actionItems.map((it, index) => {
                    newObject[itemIndex][it.name] = it.value;
                }
            )

            dataForSubmit[itemIndex] = {};

            let actionFieldsValidated = fieldsAction;
            actionFieldsValidated[itemIndex] = FieldsManager.validateFields(fieldsAction[itemIndex])
            if (FieldsManager.checkFieldsForErrors(actionFieldsValidated[itemIndex])) {
                Object.assign(dataForSubmit[itemIndex], FieldsManager.getFieldKeyValues(fieldsAction[itemIndex]))
            } else {
                setFieldsAction(actionFieldsValidated)
            }

            dataForSubmit[itemIndex]['Parts'] = item['Parts']
            dataForSubmit[itemIndex]['Labor'] = item['Labor']
        })

        dataItems.WorkOrderID = Number(ID);
        dataItems.Items = dataForSubmit;
        dataItems.InitialComplaint = initialComplaintFields.InitialComplaint.value;
        dataItems.SurchargeAmount = TotalAmountSupplies();
        dataItems.Misc = Object.assign({}, dataMisc);

        if (Object.values(fieldsAction).every(item => FieldsManager.checkFieldsForErrors(item))) {
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: dataItems,
                query: {WorkOrderID: ID},
                notificationMessage: translate("message.items_updated"),
                resource: Resources.WorkOrderParts,
                piggyResource: Resources.WorkOrderParts,
                successMessage: "Labor and parts items saved"
            }));
        } else {
            scrollErrorIntoViewFieldsItems(Object.values(fieldsAction))
        }

        setIsDirty(false)
    }

    const ShopSuppliesTotalAmount = () => {
        switch (dataSettings?.ShopSuppliesSurchargeTypeID) {
            case 1:
                return 0;
            case 2:
                return totalPriceLabor * (dataSettings?.ShopSuppliesAmount - 1).toFixed(2);
            case 3:
                return (totalPriceLabor + totalPriceParts) * (dataSettings?.ShopSuppliesAmount - 1).toFixed(2);
            default:
                return 0;
        }
    }

    const TotalAmountSupplies = () => {
        let suppliesAmount = ShopSuppliesTotalAmount()

        if (dataSettings.ShopSuppliesMinAmount && suppliesAmount < dataSettings.ShopSuppliesMinAmount && totalPriceLabor) {
            suppliesAmount = dataSettings.ShopSuppliesMinAmount
        }

        if (dataSettings.ShopSuppliesMaxAmount && suppliesAmount > dataSettings.ShopSuppliesMaxAmount) {
            suppliesAmount = dataSettings.ShopSuppliesMaxAmount
        }

        return suppliesAmount.toFixed(2)
    }

    const toggleProcessPreview = (item = null) => {
        setPreviewDocument(item);
        setPreviewDialog(!previewDialog);
    }

    const toggleAiDialog = (item = null) => {
        if (!aiDialog) {
            setAILoading(true)
            getAiRecon().then((res) => {
                setAiData(res.data);
                setAILoading(false)
            });
        } else {
            setAdditionalAiInfo("")
        }
        setAiDialog(!aiDialog);
    }

    const askAIAgain = () => {
        setAILoading(true)
        getAiRecon().then((res) => {
            setAiData(res.data);
            setAILoading(false)
        });
    }

    const fillFromAi = () => {
        let newData = aiData?.ActionItems.map(it => {
            return {
                "ActionItem": it?.Complaint,
                "ActionItemDescription": it?.Correction,
                "Parts": [],
                "Labor": it?.Labor.map(iit => {
                    return getLaborFields({
                        Description: iit.LaborDescription,
                        LaborHours: iit?.LaborHours
                    })
                })
            }
        })
        data = newData
        forceUpdate()
        setIsDirty(true)
        toggleAiDialog()
    }

    const getAiRecon = () => {
        return axios.get(
            Env.getWorkOrderAiUrl('prompt', {
                input: initialComplaintFields?.InitialComplaint?.value ?? "",
                make: dataUnit?.Manufacturer,
                model: dataUnit?.Model,
                year: dataUnit?.Year,
                type: dataUnit?.Type,
                additional: additionalAiInfo ?? ""
            }),
            {
                headers: {
                    Authorization: `Bearer ${getJWT().access_token}`,
                },
            }
        )
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (data) {
            data.map((it, index) => {

                setFieldsAction(prevState => ({
                    ...prevState,
                    [index]: getFieldsAction(it, isInvoiced)
                }))
            })
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            setFieldsAction(prevState => ({
                ...prevState,
                [data.length]: getFieldsAction({}, isInvoiced)
            }))
        }
    }, [data.length]);

    useEffect(() => {
        setInitialComplaintFields(getInitialComplaintFields(dataObject, isInvoiced))
    }, [dataObject.InitialComplaint])

    /** Component Body
     ================================================================= */
    return (
        <PageTabWithFooter
            isLoading={isLoading}
            buttons={
                [
                    {
                        className: "btn btn-primary",
                        onClick: () => submit(),
                        disabled: !isDirty,
                        label: translate("btn.save")
                    },
                    {
                        className: "btn btn-outline",
                        disabled: !isDirty,
                        onClick: () => {
                            handleCancelClick();
                        },
                        label: translate("btn.cancel")
                    },
                ]
            }
        >
            <div className="py-2 space-y-4 w-full max-w-7xl mx-auto min-w-[60rem] mb-10 mt-4">

                {!!isInvoiced && (
                    <div className="my-2">
                        <InfoParagraph
                            className=""
                            message={translate("field.WOisInvoiced")}
                        />
                    </div>
                )}

                <div className="flex items-center justify-end gap-4">
                    <Button appearance={'secondary'} addClass={"flex ml-auto"} disabled={isInvoiced} onClick={() => {
                        toggleAiDialog()
                    }}><BoltIcon className={"w-8 h-8"}/> {translate("btn.ask_ai")}</Button>

                    <Button appearance={'primary'} addClass={"flex"} disabled={isInvoiced} onClick={() => {
                        toggleProcessPreview()
                    }}>{translate("btn.preview_estimate")}</Button>
                </div>

                {fieldsToHtml(Object.values(Object.assign({}, initialComplaintFields)), translate, handleInitialComplaintInputChange)}

                <WorkOrdersLaborPartsPrices
                    isResourceLoading={isLoading}
                    totalPriceParts={totalPriceParts}
                    totalPriceLabor={totalPriceLabor}
                    shopSupplies={dataSettings}
                    dataObject={dataObject}
                    dataMiscTotal={dataMiscTotal}
                />

                {data.map((it, index) => {
                    let dataPartsTable = it.Parts;
                    dataPartsTable.forEach((element) => {
                        element.ind = index
                    });

                    const dataPartsTableCalculate = dataPartsTable.map((it) => {
                        // it.CalculatedPartsPrice = numberWithCommasToBack(it.PartPrice) * Number(it.PartQty) ?? 0;
                        it.CalculatedPartsPrice = numberWithCommasToBack(it.PartPrice) * (Number(it.PartQty) ?? 0) * (Number(it.TaxAmount ?? 1));
                        return it;

                    });

                    const dataPartsTableTotal = dataPartsTableCalculate.reduce((memo, it) => {
                        memo += it.CalculatedPartsPrice
                        return memo
                    }, 0)

                    let dataLaborTable = it.Labor;
                    dataLaborTable.forEach((element) => {
                        element.ind = index
                    });

                    const dataLaborTableCalculate = dataLaborTable.map((it) => {
                        // it.CalculatedLaborPrice = numberWithCommasToBack(it.LaborPrice) * Number(it.LaborHours) ?? 0;
                        it.CalculatedLaborPrice = numberWithCommasToBack(it.LaborPrice) * (Number(it.LaborHours) ?? 0) * (Number(it.TaxAmount ?? 1));
                        return it;
                    });

                    const dataLaborTableTotal = dataLaborTableCalculate.reduce((memo, it) => {
                        memo += it.CalculatedLaborPrice
                        return memo
                    }, 0)

                    return (
                        <Card>
                            {/*Action fields*/}
                            <div className={"flex items-center justify-between"}>
                                <h3 className={"text-lg font-bold "}>#{index + 1}</h3>
                                <button disabled={isInvoiced}
                                        className={"flex"}
                                        onClick={() => {
                                            delete data[index];
                                            forceUpdate();
                                            setIsDirty(true)
                                        }
                                        }
                                >
                                    <XCircleIcon className={"w-8 h-8"}/>
                                </button>
                            </div>
                            <div className={"grid grid-cols-12 gap-x-6 gap-y-4"} id={`itemIndex${index}`}>
                                {fieldsToHtml(Object.values(Object.assign({}, fieldsAction[index])), translate, (name, value) => handleInputChange(name, value, index, fieldsAction[index]), {
                                    CustomCodeID: {
                                        api: 'api/' + Resources.WorkOrdersCustomCodes,
                                        query: {},
                                        searchMap: (it) => ({
                                            label: it.CustomCode,
                                            value: it.CustomCodeID,
                                        })
                                    },
                                    ServiceBayID: {
                                        api: 'api/' + Resources.ServiceBaysQuick,
                                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                        searchMap: (item) => ({
                                            value: item.ServiceBayID,
                                            label: item.BayNumber
                                        })
                                    },
                                    AssignedContactID: {
                                        api: 'api/' + Resources.Contacts,
                                        query: {},
                                        searchMap: (it) => ({
                                            label: `${it.FirstName} ${it.LastName}`,
                                            value: it.ContactID,
                                        })
                                    },
                                    ActionItemStatusID: {
                                        1: "Open",
                                        2: "Rejected",
                                        3: "In progress",
                                        4: "Completed"
                                    }
                                })}
                            </div>


                            {/*Parts*/}
                            <div className={"space-y-4"}>
                                <WorkOrdersLaborPartsPrices
                                    totalPriceParts={dataPartsTableTotal}
                                    totalPriceLabor={dataLaborTableTotal}
                                />

                                <ResourceTable
                                    data={dataPartsTable}
                                    fields={getPartsFields()}

                                    translate={translate}
                                    options={
                                        {
                                            behaviour: {
                                                rowSelect: false,
                                                canAdjustWidth: false,
                                                hasMenu: false

                                            },
                                            style: {
                                                condensed: true,
                                                isGPUAccelerated: true,
                                                horizontalLines: true,
                                                verticalLines: true
                                            }
                                        }
                                    }
                                    actions={[
                                        {
                                            title: () => translate('btn.edit'),
                                            visible: () => !isInvoiced,
                                            action: (item) => handleEditPartClick(item),
                                            icon: () => PencilIcon
                                        },
                                        {
                                            title: () => translate('btn.delete'),
                                            visible: (it) => !isInvoiced && (it.ParentPartID === null || it.ParentPartID === undefined),
                                            action: (item) => handleDeletePartClick(item),
                                            icon: () => TrashIcon
                                        }
                                    ]}

                                    onRowClick={!isInvoiced && handleEditPartClick}
                                />

                                <NoRecords
                                    show={!dataPartsTable.length}
                                    addClass="p-4"
                                    title="No Parts"
                                    text={translate("text.add_part_button")}
                                />

                                <Buttons
                                    buttons={[
                                        {
                                            label: translate("btn.add_part"),
                                            type: "primary",
                                            disabled: isInvoiced,
                                            onClick: () => handleCreatePartClick(index),
                                            className: "btn btn-primary flex ml-auto"

                                        }
                                    ]}
                                />
                            </div>

                            {/*Labor*/}
                            <div className={"space-y-2"}>
                                <ResourceTable
                                    data={dataLaborTable}
                                    fields={getLaborFields()}

                                    translate={translate}
                                    options={
                                        {
                                            behaviour: {
                                                rowSelect: false,
                                                canAdjustWidth: false,
                                                hasMenu: false

                                            },
                                            style: {
                                                condensed: true,
                                                isGPUAccelerated: true,
                                                horizontalLines: true,
                                                verticalLines: true
                                            }
                                        }
                                    }

                                    actions={[
                                        {
                                            title: () => translate('btn.edit'),
                                            visible: () => !isInvoiced,
                                            action: (item) => handleEditLaborClick(item),
                                            icon: () => PencilIcon
                                        },
                                        {
                                            title: () => translate('btn.delete'),
                                            visible: () => !isInvoiced,
                                            action: (item) => handleDeleteLaborClick(item),
                                            icon: () => TrashIcon
                                        }
                                    ]}

                                    onRowClick={!isInvoiced && handleEditLaborClick}
                                />
                                <NoRecords
                                    show={!dataLaborTable.length}
                                    addClass="p-4"
                                    title="No Labor"
                                    text={translate("text.add_labor_button")}
                                />
                                <Buttons
                                    buttons={[
                                        {
                                            label: translate("btn.add_labor"),
                                            type: "primary",
                                            disabled: isInvoiced,
                                            onClick: () => handleCreateLaborClick(index),
                                            className: "btn btn-primary flex ml-auto"
                                        }
                                    ]}
                                />

                            </div>
                        </Card>
                    )
                })}

                <button className={"btn btn-primary flex mx-auto"} disabled={isInvoiced} onClick={
                    () => {
                        let newData = data;
                        newData[data.length] = {
                            "Parts": [],
                            "Labor": []
                        }
                        forceUpdate()
                        setIsDirty(true)
                    }
                }> Add new item
                </button>


                <Card>
                    <div className={"flex justify-center text-lg"}>Misc. charges</div>
                    <div>
                        <Buttons
                            buttons={[
                                {
                                    label: translate("btn.add_misc"),
                                    type: "primary",
                                    disabled: isInvoiced,
                                    onClick: () => handleCreateMiscClick(),
                                    className: "btn btn-primary flex ml-auto mb-4"

                                }
                            ]}
                        />

                        <ResourceTable
                            data={dataMisc}
                            fields={getMiscFields()}

                            translate={translate}
                            options={
                                {
                                    behaviour: {
                                        rowSelect: false,
                                        canAdjustWidth: false,
                                        hasMenu: false

                                    },
                                    style: {
                                        condensed: true,
                                        isGPUAccelerated: true,
                                        horizontalLines: true,
                                        verticalLines: true
                                    }
                                }
                            }
                            actions={[
                                {
                                    title: () => translate('btn.edit'),
                                    visible: () => !isInvoiced,
                                    action: (item) => handleEditMiscClick(item),
                                    icon: () => PencilIcon
                                },
                                {
                                    title: () => translate('btn.delete'),
                                    visible: (it) => !isInvoiced,
                                    action: (item) => handleDeleteMiscClick(item),
                                    icon: () => TrashIcon
                                }
                            ]}

                            onRowClick={!isInvoiced && handleEditMiscClick}
                        />

                        <NoRecords
                            show={!dataMisc.length}
                            addClass="p-4"
                            title="No Misc. charges"
                        />
                    </div>

                    {/*<button className={"btn btn-outline flex mx-auto"} onClick={*/}
                    {/*    () => {handleCreateMiscClick()}}*/}
                    {/*> {translate("btn.add_misc")}*/}
                    {/*</button>*/}
                </Card>

                <ModalConfirm
                    show={confirmDialogData?.isOpen ?? false}
                    type={confirmDialogData?.type}
                    text={confirmDialogData?.content}
                    title={confirmDialogData?.title}
                    onConfirm={confirmDialogData?.onClick}
                    closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                    buttonLabel={confirmDialogData?.buttonLabel}
                    translate={translate}
                    onClose={closeConfirmDialog}
                />

                <ModalSaveResource
                    show={isPartsFromDialogOpen}
                    initialFocusID="PartName"
                    fields={getPartsFields(selectedItem)}
                    title={selectedItem ? translate("modal_heading.edit_part_item") : translate("modal_heading.add_part")}
                    onClose={() => {
                        setIsPartsFormDialogOpen(false);
                    }}
                    handleInputChange={handlePartsInputChange}
                    onSubmit={(params) => {
                        if (params?.PartName.label) {
                            params.PartName = params.PartName.label;
                        }


                        if (!!params) {
                            if (selectedItem) {
                                let newData = data;

                                if (selectedItem.ParentPartID !== undefined) {
                                    params.ParentPartID = selectedItem.ParentPartID
                                }

                                params.ChildPartID = selectedItem.ChildPartID
                                newData[selectedItem.ind]['Parts'][selectedItem.dataIndex] = params

                                setIsPartsFormDialogOpen(false);
                                setIsDirty(true)
                            } else {

                                let newData = data;

                                let ParamsFirst = JSON.parse(JSON.stringify(params));

                                let lengthFirst = newData[partCreateIndex]['Parts'].length
                                newData[partCreateIndex]['Parts'][lengthFirst] = ParamsFirst

                                ParamsFirst.ChildPartID = lengthFirst

                                let DirtyParams = JSON.parse(JSON.stringify(params));

                                if (DirtyParams.DirtyProductServiceID) {
                                    DirtyParams.ProductServiceID = DirtyParams.DirtyProductServiceID
                                    delete DirtyParams.InherentProductServiceID
                                    delete DirtyParams.InherentProductService

                                    if (DirtyParams.DirtyProductService) {
                                        DirtyParams.PartName = DirtyParams.DirtyProductService;
                                        DirtyParams.ParentPartID = lengthFirst
                                    }
                                    let length = newData[partCreateIndex]['Parts'].length

                                    newData[partCreateIndex]['Parts'][length] = DirtyParams
                                }

                                let InherentParams = JSON.parse(JSON.stringify(params));

                                if (InherentParams.InherentProductServiceID) {
                                    InherentParams.ProductServiceID = InherentParams.InherentProductServiceID
                                    delete InherentParams.DirtyProductService
                                    delete InherentParams.DirtyProductServiceID

                                    if (InherentParams.InherentProductService) {
                                        InherentParams.PartName = InherentParams.InherentProductService;
                                        InherentParams.ParentPartID = lengthFirst
                                    }
                                    let length = newData[partCreateIndex]['Parts'].length
                                    newData[partCreateIndex]['Parts'][length] = InherentParams
                                }

                                setIsPartsFormDialogOpen(false);
                                setIsDirty(true)


                            }

                            setSelectedItem(params); // in case of server error save form data
                        }
                    }}
                    addFieldContainerClass={"col-span-6"}
                    addBodyClass={"pb-12 text-tm-gray-900"}
                    resource={resource}
                    metadata={getSelects()}
                    translate={translate}
                />

                <ModalSaveResource
                    show={isLaborFromDialogOpen}
                    initialFocusID="LaborPrice"
                    fields={getLaborFields(selectedItem)}
                    title={selectedItem ? translate("modal_heading.edit_labor_item") : translate("modal_heading.add_labor_item")}
                    onClose={() => {
                        setIsLaborFormDialogOpen(false);
                    }}
                    handleInputChange={handleLaborInputChange}
                    onSubmit={(params) => {
                        if (!!params) {
                            if (selectedItem) {
                                let newData = data;
                                newData[selectedItem.ind]['Labor'][selectedItem.dataIndex] = params

                                setIsLaborFormDialogOpen(false);
                                setIsDirty(true)

                            } else {
                                let newData = data;
                                let length = newData[laborCreateIndex]['Labor'].length
                                newData[laborCreateIndex]['Labor'][length] = params

                                setIsLaborFormDialogOpen(false);
                                setIsDirty(true)
                            }

                            setSelectedItem(params); // in case of server error save form data
                        }
                    }}
                    addFieldContainerClass={"col-span-6"}
                    addBodyClass={"pb-12 text-tm-gray-900"}
                    metadata={getSelects()}
                    resource={resource}
                    translate={translate}
                />

                <ModalDefault
                    show={previewDialog}
                    limitHeight={true}
                    widthClass={'max-w-6xl'}
                    title={translate('text.preview_document')}

                    onButtonClick={handleDownloadDocument}
                    buttonLabel={translate('Download')}

                    customButtonsHTML={(
                        <div>
                            <button
                                className={`btn btn-primary mr-2`}
                                onClick={() => onDocumentPrint(Env.getApiUrl('api/' + Resources.WorkOrderEstimate, Object.assign({}, {
                                    WorkOrderID: ID,
                                    token: getJWT().access_token,
                                    type: 1,
                                    name: 'estimate_' + ID + '.pdf'
                                })), 'pdf')}
                            >
                                {translate('btn.Print')}
                            </button>
                        </div>
                    )}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={toggleProcessPreview}
                    isLoading={false}
                >
                    {!!previewDialog && (
                        <FileViewer
                            fileType={'pdf'}
                            filePath={Env.getApiUrl('api/' + Resources.WorkOrderEstimate, Object.assign({}, {
                                WorkOrderID: ID,
                                token: getJWT().access_token,
                                type: 1,
                                name: 'estimate_' + ID + '.pdf'
                            }))}
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>


                <ModalDefault
                    show={aiDialog}
                    limitHeight={true}
                    widthClass={'max-w-6xl'}
                    title={translate('text.ai_dialog')}

                    onButtonClick={fillFromAi}
                    buttonLabel={translate('Apply suggestion')}

                    customButtonsHTML={(
                        <div>
                            <button
                                className={`btn btn-primary mr-2`}
                                onClick={() => {
                                    askAIAgain();
                                }}
                            >
                                Ask again
                            </button>
                        </div>
                    )}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={toggleAiDialog}
                >
                    <WorkOrdersLaborPartsAiModal
                        aiData={aiData}
                        aiLoading={aiLoading}
                        translate={translate}
                        additionalAiInfo={additionalAiInfo}
                        setAdditionalAiInfo={setAdditionalAiInfo}
                    />
                </ModalDefault>

                <ModalSaveResource
                    show={isMiscFormDialogOpen}
                    initialFocusID=""
                    fields={getMiscFields(selectedItem)}
                    title={selectedItem ? translate("btn.edit_misc") : translate("btn.add_misc")}
                    onClose={() => {
                        setIsMiscFormDialogOpen(false);
                    }}
                    handleInputChange={handleMiscInputChange}
                    onSubmit={(params) => {
                        if (!!params) {
                            let newData = dataMisc;
                            if (selectedItem) {
                                newData[selectedItem.dataIndex] = params

                                setIsMiscFormDialogOpen(false);
                                setIsDirty(true)
                            } else {
                                let length = newData.length
                                newData[length] = params

                                setIsMiscFormDialogOpen(false);
                                setIsDirty(true)
                            }

                            setSelectedItem(params); // in case of server error save form data
                        }
                    }}
                    addFieldContainerClass={"col-span-6"}
                    addBodyClass={"pb-12 text-tm-gray-900"}
                    metadata={getSelects()}
                    resource={resource}
                    translate={translate}
                />

            </div>
        </PageTabWithFooter>
    )
}

const getInitialComplaintFields = (item = {}, isInvoiced) => {
    const fieldTemplates = {
        InitialComplaint: new Field('InitialComplaint', '', [''], isInvoiced, 'textarea'),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getFieldsAction = (item = {}, isInvoiced) => {
    const fieldTemplates = {
        ActionItem: new Field('ActionItem', '', ['empty'], isInvoiced, 'textarea', {addContainerClass: "col-span-6"}),
        ActionItemDescription: new Field('ActionItemDescription', '', [''], isInvoiced, 'textarea', {addContainerClass: "col-span-6"}),
        ActionItemStatusID: new Field('ActionItemStatusID', '', ['empty'], isInvoiced, 'select', {addContainerClass: "col-span-3"}),
        CustomCodeID: new Field('CustomCodeID', '', [], isInvoiced, 'select-search', {
            addContainerClass: "col-span-3",
            isClearable: true
        }),
        ServiceBayID: new Field('ServiceBayID', '', [], isInvoiced, 'select-search', {
            addContainerClass: "col-span-3",
            isClearable: true
        }),
        AssignedContactID: new Field('AssignedContactID', '', [''], isInvoiced, 'select-search', {
            addContainerClass: "col-span-3",
            isClearable: true
        }),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getPartsFields = (item = {}) => {
    // item.CalculatedPartsPrice = (numberWithCommasToBack(item.PartPrice) * (Number(item.PartQty) ?? 0) * (Number(item.TaxAmount) ?? 0).toString())
    item.TotalAmount = formatMoney(item.TotalAmount)
    const fieldTemplates = {
        ProductServiceID: new Field('ProductServiceID', '', [''], false, 'hidden', {hideTable: true}),
        DirtyProductServiceID: new Field('DirtyProductServiceID', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        InherentProductServiceID: new Field('InherentProductServiceID', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        DirtyProductService: new Field('DirtyProductService', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        InherentProductService: new Field('InherentProductService', '', [''], false, 'hidden', {
            hideTable: true,
            hideDialog: true
        }),
        PartName: new Field('PartName', '', ['empty'], false, 'creatable-select-search', {addContainerClass: "col-span-full"}),
        PartQty: new Field('PartQty', '', ['empty'], false, 'integer', {addContainerClass: "col-span-3"}),
        PartPrice: new Field('PartPrice', '', ['empty'], false, 'money', {
            addContainerClass: "col-span-3",
            render: (it) => genericMoneyFormatter(it.PartPrice)
        }),

        TaxID: new Field('TaxID', '', [''], false, 'select-search', {addContainerClass: "col-span-3", hideTable: true}),
        TaxAmount: new Field('TaxAmount', '1', [''], false, 'float', {
            addContainerClass: "col-span-3",
            hideTable: true,
            hideDialog: true
        }),
        Tax: new Field('Tax', '', [''], false, 'text', {addContainerClass: "col-span-3", hideDialog: true}),
        TotalAmount: new Field('TotalAmount', '', [''], true, 'money', {
            addContainerClass: "col-span-3",
            omitSort: true,
            render: (it) => genericMoneyFormatter(numberWithCommasToBack(it.TotalAmount))
        }),
        HasWarranty: new Field('HasWarranty', '', [''], false, 'checkbox', {
            label: "HasWarranty",
            hasActiveBadge: true,
            addContainerClass: "col-span-3 row-start-3"
        }),
        Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}, {rows: 8})
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getLaborFields = (item = {}) => {
    // item.CalculatedLaborPrice = numberWithCommasInputChange((numberWithCommasToBack(item.LaborPrice) * Number(item.LaborHours) ?? 0).toString())
    item.TotalAmount = formatMoney(item.TotalAmount)
    const fieldTemplates = {
        Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}, {rows: 4}),
        LaborHours: new Field('LaborHours', '', ['empty'], false, 'float', {
            addContainerClass: "col-span-3",
            addTableHeaderClass: "mr-auto"
        }),
        LaborPrice: new Field('LaborPrice', '', ['empty'], false, 'money', {
            addContainerClass: "col-span-3",
            addTableHeaderClass: "mr-auto"
        }),
        TaxID: new Field('TaxID', '', [''], false, 'select-search', {addContainerClass: "col-span-3", hideTable: true}),
        TaxAmount: new Field('TaxAmount', 1, [''], false, 'float', {
            addContainerClass: "col-span-3",
            hideTable: true,
            hideDialog: true
        }),
        Tax: new Field('Tax', '', [''], false, 'text', {addContainerClass: "col-span-3", hideDialog: true}),
        TotalAmount: new Field('TotalAmount', '', [''], true, 'money', {
            addContainerClass: "col-span-3",
            omitSort: true,
            render: (it) => genericMoneyFormatter(numberWithCommasToBack(it.TotalAmount))
        }),
        Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}, {rows: 4})
    }

    return fillFieldsFromData(fieldTemplates, item)
}

const getMiscFields = (item = {}) => {
    const fieldTemplates = {
        Description: new Field('Description', '', ['empty'], false, 'textarea', {addContainerClass: "col-span-full"}),
        MiscQty: new Field('MiscQty', '', ['empty'], false, 'float', {addContainerClass: "col-span-3"}),
        MiscPrice: new Field('MiscPrice', '', ['empty'], false, 'float', {addContainerClass: "col-span-3"}),
        TaxID: new Field('TaxID', '', [''], false, 'select-search', {addContainerClass: "col-span-3", hideTable: true}),
        Tax: new Field('Tax', '', [''], false, 'text', {addContainerClass: "col-span-3", hideDialog: true}),
        TaxAmount: new Field('TaxAmount', 1, [''], false, 'float', {
            addContainerClass: "col-span-3",
            hideTable: true,
            hideDialog: true
        }),
        TotalAmount: new Field('TotalAmount', '', [''], true, 'money', {addContainerClass: "col-span-3",}),
    }

    return fillFieldsFromData(fieldTemplates, item)
}

function getSelects() {
    return {
        PartName: {
            api: 'api/' + Resources.WorkOrderPartsQuick,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.ProductService,
                    value: it.ProductServiceID,
                    DirtyProductServiceID: it.DirtyProductServiceID,
                    DirtyProductService: it.DirtyProductService,
                    InherentProductServiceID: it.InherentProductServiceID,
                    InherentProductService: it.InherentProductService
                })
            }
        },
        TaxID: {
            api: 'api/' + Resources.AccountingTaxesQuick,
            query: {},
            searchMap: (it) => {
                return ({
                    label: it.Tax,
                    value: it.TaxID,
                    amount: it.Amount
                })
            }
        }
    }
}





