import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProp} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import WorkOrdersCreateImagesTab from "./work-orders-create-image-tab";
import WorkOrdersCreateInfoTab from "./work-orders-create-info-tab";
import {Field, FieldsManager} from "../../../data/services/fields";
import {getDefaultQueryFields, getUser, updateQuery} from "../../../util/util";
import {createResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import InformationCircleIcon from "@heroicons/react/20/solid/esm/InformationCircleIcon";
import ListBulletIcon from "@heroicons/react/24/outline/ListBulletIcon";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import {getSecondResource} from "../../../data/actions/secondResource";
import useQuery from "../../../hooks/use-query";
import Layout from "../../../common/components/layout";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PageTabWithFooter from "../../../common/components/layout/layout-components/page/page-tab-with-footer";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NavResponsive from "../../../common/components/nav-responsive";
import CreateDocumentsTab from "../../../common/components/tabs/documents-tab/documents-tab-create";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ModalDefault from "../../../common/components/modal/modal-default";
import ResourceTableTopBar from "../../../common/components/resource-table/table-components/resource-table-top-bar";
import FieldSearch from "../../../common/components/fields/field-text/search";
import ResourceTable from "../../../common/components/resource-table";
import FieldOptions from "../../../common/components/fields/field-options";
import ModalCreateSuccessNew from "../../../common/components/modal/create-success-modal-new";

export default function WorkOrdersCreate({history, match, translate}) {
    const handleOrganizationQuickView = (it) => {
        dispatch(showModal('ViewCustomerCard', {
            OrganizationID: it.value.value
        }))
    }

    const getBasicFields = (item = {}) => {
        const fieldTemplates = {
            WorkOrderID: new Field('WorkOrderID', '', [''], false, 'hidden'),
            // RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', ['empty'], false, 'select', {addContainerClass: "col-span-6"}),
            // WorkOrder: new Field('WorkOrder', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),

            ChargeTo: new Field('ChargeTo', '', [''], false, 'button-group', {
                data: {
                    0: translate('field.ContactID'),
                    1: translate('field.OrganizationID'),
                    2: translate('field.Non-ChargeableRepair')
                },
                hideTable: true,
                addContainerClass: 'col-span-12'
            }),
            IsInHouseRepair: new Field('IsInHouseRepair', 0, [], false, "hidden"),
            ChargeContactID: new Field('ChargeContactID', '', ['empty'], false, 'select-search', {
                addContainerClass: 'col-span-6',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => dispatch(showModal('ViewContactCard', {ContactID: it?.value.value})),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }),
            ChargeOrganizationID: new Field('ChargeOrganizationID', '', [''], true, 'select-search', {
                addContainerClass: 'col-span-6',
                hideTable: true,
                fieldOptions: (it) => {
                    return (
                        <FieldOptions
                            options={[
                                {
                                    icon: InformationCircleIcon,
                                    onClick: () => handleOrganizationQuickView(it),
                                    isVisible: !!it.value
                                }
                            ]}
                        />
                    )
                }
            }),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {addContainerClass: "col-span-6"}),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search', {addContainerClass: "col-span-6"}),
            Notes: new Field('Notes', '', [''], false, 'textarea', {
                label: 'internal_notes',
                addContainerClass: "col-span-6"
            }),
            ExternalNotesCustomer: new Field('ExternalNotesCustomer', '', [''], false, 'textarea', {
                addContainerClass: "col-span-6"
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getAssignedFields = (item = {}, translate, handleVendorInfoClick) => {
        const fieldTemplates = {
            OwnedBy: new Field('OwnedBy', 0, [''], false, 'button-group', {
                data: {1: translate('btn.internal_shop'), 0: translate('btn.third_party_shop')},
                addContainerClass: 'col-span-full',
                hideLabel: true
            }),
            AssignedVendorID: new Field(
                'AssignedVendorID',
                '',
                item.OwnedBy === 0 ? ['empty'] : [''],
                item.OwnedBy === 1,
                'select-search',
                {
                    addContainerClass: "col-start-1 col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleVendorInfoClick(it?.value?.metadata),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
                {isClearable: true}
            )
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item);

        if (!!filledFields.AssignedVendorID.value) {
            filledFields.AssignedVendorID.value.metadata = item.AssignedVendorInfo;
        }

        return filledFields;
    }

    const getAssetFields = (item = {}, translate, handleTruckInfoClick, handleTrailerInfoClick, handleTruckWoClick, handleTrailerWoClick) => {
        const fieldTemplates = {
            TruckID: new Field(
                'TruckID',
                '',
                !!item.TrailerID ? [''] : ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: ListBulletIcon,
                                        onClick: () => handleTruckWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTruckInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }
                                ]}
                            />
                        )
                    }
                },
                {
                    isClearable: true
                }
            ),
            TrailerID: new Field(
                'TrailerID',
                '',
                !!item.TruckID ? [''] : ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: ListBulletIcon,
                                        onClick: () => handleTrailerWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTrailerInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }]}
                            />
                        )
                    }
                },
                {isClearable: true}
            ),
            CurrentOdometerValue: new Field('CurrentOdometerValue', '', [''], false, 'text', {addContainerClass: "col-span-6"})
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item);


        return filledFields;
    }

    function getFields(item = {}) {
        const TruckID = !!item?.TruckID;
        const TrailerID = !!item?.TrailerID;

        const fieldTemplates = {
            WorkOrderID: new Field('WorkOrderID', '', [''], false, 'custom', {
                render: (it) => {
                    return (<button
                        className={'btn h-7 w-auto p-2 rounded-xl btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0'}
                        onClick={() => {
                            history.push('/work-orders/' + it.WorkOrderID);
                            window.location.reload(true)
                        }}>{it.WorkOrderID}</button>)
                }
            }),
            WorkOrder: new Field('WorkOrder', '', ['empty'], false, "text", {}, {autofocus: true}),
            RequestedServiceCategoryID: new Field('RequestedServiceCategoryID', '', ['empty'], false, 'select'),
            CustomCodeID: new Field('CustomCodeID', '', [''], false, 'select-search', {addContainerClass: "col-span-6"}),
            TruckID: new Field('TruckID', '', TrailerID ? [''] : ['empty'], false, 'select-search', {addContainerClass: "col-span-6 col-start-1"}, {isClearable: true}),
            TrailerID: new Field('TrailerID', '', TruckID ? [''] : ['empty'], false, 'select-search', {addContainerClass: "col-span-6"}, {isClearable: true}),

            RequestDate: new Field('RequestDate', '', [''], false, 'datetime', {addContainerClass: "col-span-6"}),

            Notes: new Field('Notes', '', [''], false, 'textarea', {label: "Description"}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getComplaintFields = (item = {}, translate) => {
        const fieldTemplates = {
            InitialComplaint: new Field('InitialComplaint', '', [''], false, 'textarea',{addContainerClass: "col-span-full"})
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    const getPrimaryField = () => "WorkOrderID";

    const getQueryFields = (translate) => {
        return getDefaultQueryFields(getPrimaryField(), translate);
    }

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const document = useSelector((state) => state.document);
    const download = useSelector((state) => state.download);

    const isLoading = getProp(resource, "isLoading", false);
    const documentIsLoading = getProp(document, "isLoading", false);
    const downloadIsLoading = getProp(download, "isLoading", false);
    const data = getProp(resource, "data", {});
    const [dataImages, setDataImages] = useState([]);
    const [dataDocuments, setDataDocuments] = useState([]);
    const [isStateDirty, setIsStateDirty] = useState(false);
    const [tabs, setTabs] = useState(getTabs());
    const currentTab = tabs.find(it => it.current);
    const [fieldsBasic, setFieldsBasic] = useState(getBasicFields({}, translate));
    const [fieldsAssigned, setFieldsAssigned] = useState(getAssignedFields({}, translate));
    const [fieldsAssets, setFieldsAssets] = useState(getAssetFields({}, translate));
    const [fieldsComplaint, setFieldsComplaint] = useState(getComplaintFields({}, translate));
    // const [fieldsCustom, setFieldsCustom] = useState(getCustomFields({}, translate));
    const [showTruckWoDialog, setShowTruckWoDialog] = useState(false);
    const [showTrailerWoDialog, setShowTrailerWoDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    let isItemCreatedRef = useRef(false);

    const secondResource = useSelector((state) => state.secondResource)

    const secondData = getProp(secondResource, 'data.list', [])
    const secondCount = getProp(secondResource, 'data.count', 0)
    const secondIsLoading = getProp(secondResource, 'isLoading', false)
    const [fields, setFields] = useState(getFields({}));
    const layoutRef = useRef();
    const [isLayoutScrolled, setIsLayoutScrolled] = useState(false)
    /** Constants
     ================================================================= */
    const getResourcePath = () => Resources.WorkOrders;
    const getListPath = () => getResourcePath() + "_wo"
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())

    const fetchSecondData = (item, query, type) => {
        dispatch(getSecondResource({
            user: getUser(),
            query: Object.assign({
                searchFields: JSON.stringify({
                    [type]: item,
                })
            }, query)
            ,
            resource: Resources.WorkOrders,
        }))
    }

    const handleQueryChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, name, value);
        if (name === 'query') {
            queryUpdate.offset.value = "0"
        }

        setQueryFields(queryUpdate);
        fetchSecondData(selectedItem.TruckID ?? selectedItem.TrailerID, FieldsManager.getFieldKeyValues(queryUpdate), selectedItem.TruckID ? 'TruckID' : 'TrailerID');
    }
    const handleVendorInfoClick = (item) => {
        dispatch(showModal('ViewVendorCard', item?.value?.metadata))
    }

    const handleContactInfoClick = (item) => {
        dispatch(showModal('ViewContactCard', item?.value?.metadata))
    }

    const handleTruckInfoClick = (truckID) => {
        dispatch(showGlobalModal("ViewTruckCard", truckID))
    }

    const handleTrailerInfoClick = (trailerID) => {
        dispatch(showGlobalModal("ViewTrailerCard", trailerID))
    }

    const handleTruckWoClick = (item) => {
        setShowTruckWoDialog(!showTruckWoDialog);
        setSelectedItem(item);
        fetchSecondData(item?.TruckID, FieldsManager.getFieldKeyValues(queryFields), 'TruckID');
        setQueryFields(getQueryFields(translate));
    }
    const handleTrailerWoClick = (item) => {
        setShowTrailerWoDialog(!showTrailerWoDialog);
        setSelectedItem(item);
        fetchSecondData(item?.TrailerID, FieldsManager.getFieldKeyValues(queryFields), 'TrailerID');
        setQueryFields(getQueryFields(translate));
    }

    const handleLayoutScroll = () => {
        if (layoutRef?.current.scrollTop > 100 && !isLayoutScrolled) {
            setIsLayoutScrolled(true)
        }

        if (layoutRef?.current.scrollTop <= 100 && isLayoutScrolled) {
            setIsLayoutScrolled(false)
        }
    }

    const handleScrollLayoutOnTop = () => {
        if (layoutRef?.current) {
            layoutRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    /** UI Events
     ================================================================= */
    const handleTabChange = (selectedTab) => {
        const tabsUpdate = tabs.map(tab => {
            tab.current = tab.resource === selectedTab;
            return tab;
        });

        setTabs(tabsUpdate);
    }

    const handleBasicInputChange = (name, value) => {
        let fieldsBasicUpdate = Object.assign({}, fieldsBasic);

        if (name === 'ChargeTo') {
            fieldsBasicUpdate.ChargeContactID.validate = [value == "0" ? 'empty' : '']
            fieldsBasicUpdate.ChargeOrganizationID.validate = [value == "1" ? 'empty' : '']

            fieldsBasicUpdate.ChargeContactID.disabled = value == "0" ? false : true
            fieldsBasicUpdate.ChargeOrganizationID.disabled = value == "1" ? false : true
            fieldsBasicUpdate.ChargeContactID.value = ''
            fieldsBasicUpdate.ChargeOrganizationID.value = ''

            fieldsBasicUpdate.IsInHouseRepair.value = value === 2 && 1
        }
        fieldsBasicUpdate = FieldsManager.updateField(fieldsBasicUpdate, name, value);
        fieldsBasicUpdate[name].errorMessage = "";
        setFieldsBasic(fieldsBasicUpdate);
        setIsStateDirty(true);
    }

    const handleAssignedChange = (name, value) => {
        let fieldsAssignedUpdate = Object.assign({}, fieldsAssigned);

        fieldsAssignedUpdate = FieldsManager.updateField(fieldsAssignedUpdate, name, value);

        if ("OwnedBy" === name) {
            fieldsAssignedUpdate.AssignedVendorID.disabled = value === 1;
            fieldsAssignedUpdate.AssignedVendorID.value = value === 1 && "";
            fieldsAssignedUpdate.AssignedVendorID.validate = value ? [''] : ['empty']
        }

        setFieldsAssigned(fieldsAssignedUpdate);
        setIsStateDirty(true);
    }

    const handleAssetsChange = (name, value) => {
        let fieldsAssetsUpdate = Object.assign({}, fieldsAssets);

        fieldsAssetsUpdate = FieldsManager.updateField(fieldsAssetsUpdate, name, value);

        fieldsAssetsUpdate.TruckID.validate = !!fieldsAssetsUpdate.TrailerID?.value?.value ? [''] : ['empty']
        fieldsAssetsUpdate.TrailerID.validate = !!fieldsAssetsUpdate.TruckID?.value?.value ? [''] : ['empty']

        fieldsAssetsUpdate[name]["errorMessage"] = "";

        setFieldsAssets(fieldsAssetsUpdate);
        setIsStateDirty(true);
    }

    const handleComplaintChange = (name, value) => {
        let fieldsComplaintUpdate = Object.assign({}, fieldsComplaint);

        fieldsComplaintUpdate = FieldsManager.updateField(fieldsComplaintUpdate, name, value);

        setFieldsComplaint(fieldsComplaintUpdate);
        setIsStateDirty(true);
    }

    const onSubmitClick = () => {
        const fieldsBasicValidate = FieldsManager.validateFields(fieldsBasic);
        const fieldsAssignedValidate = FieldsManager.validateFields(fieldsAssigned);
        const fieldsAssetsValidate = FieldsManager.validateFields(fieldsAssets);
        const fieldsComplaintValidate = FieldsManager.validateFields(fieldsComplaint);

        if (
            FieldsManager.checkFieldsForErrors(fieldsBasicValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssignedValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssetsValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsComplaintValidate)
        ) {
            let params = Object.assign({},
                FieldsManager.getFieldKeyValues(fieldsBasicValidate),
                FieldsManager.getFieldKeyValues(fieldsAssignedValidate),
                FieldsManager.getFieldKeyValues(fieldsAssetsValidate),
                FieldsManager.getFieldKeyValues(fieldsComplaintValidate)
            )

            delete params.WorkOrderID
            let imageParams = {}
            imageParams = dataImages.map((it, index) => imageParams[index] = it.fields)

            if (dataImages.length === 1) {
                imageParams = imageParams[0]
            }

            if (params.VMRSCode?.value) {
                params.VMRSCode = params.VMRSCode.value;
            }


            dispatch(createResource({
                user: LocalStorage.get('user'),
                params: params,
                query: {},
                fileParams: imageParams,
                resource: Resources.WorkOrders,
                piggyResource: Resources.WorkOrders,
                customID: 'WorkOrderID',
                file: dataImages,
                fileResource: Resources.WorkOrdersImages,
                document: dataDocuments,
                documentResource: Resources.WorkOrdersDocuments
            }));
            isItemCreatedRef.current = true;

        } else {
            setFieldsBasic(fieldsBasicValidate)
            setFieldsAssigned(fieldsAssignedValidate)
            setFieldsAssets(fieldsAssetsValidate)
            setFieldsComplaint(fieldsComplaintValidate)
        }
    }

    function getTabs() {
        return [
            {
                name: 'WorkOrdersInfo',
                resource: Resources.WorkOrder,
                current: true,
                visible: true
            },
            {
                name: 'Images',
                resource: Resources.WorkOrdersImages,
                current: false,
                visible: true
            },
            {
                name: 'WorkOrdersDocument',
                resource: Resources.WorkOrdersDocuments,
                current: false,
                visible: true
            }
        ]
    }

    useEffect(() => {
        if (!isLoading) {
            setFieldsBasic(getBasicFields({}, translate));
            setFieldsAssigned(getAssignedFields({}, translate, handleVendorInfoClick, handleContactInfoClick));
            // setFieldsCustom(getCustomFields({}, translate));
            setFieldsAssets(getAssetFields({}, translate, handleTruckInfoClick, handleTrailerInfoClick, handleTruckWoClick, handleTrailerWoClick));
            setFieldsComplaint(getComplaintFields({}, translate));
            setIsStateDirty(false);
            setDataImages([]);
            setDataDocuments([]);
        }
    }, [isLoading])

    /** Page Body
     ================================================================= */
    return (
        <Layout
            history={history}
            match={match}
            translate={translate}
            layoutRef={layoutRef}
            onScroll={handleLayoutScroll}
        >

            <PageTabWithFooter
                isLoading={isLoading}
                buttons={
                    [
                        {
                            className: "btn btn-primary",
                            onClick: () => onSubmitClick(),
                            disabled: !isStateDirty,
                            label: translate("btn.save")
                        },
                        {
                            className: "btn btn-outline",
                            disabled: !isStateDirty,
                            onClick: () => {
                                setFieldsBasic(getBasicFields({}, translate));
                                setFieldsAssigned(getAssignedFields({}, translate));
                                setFieldsAssets(getAssetFields({}, translate));
                                setIsStateDirty(false)
                            },
                            label: translate("btn.cancel")
                        },
                    ]
                }
            >
                <div>
                    <div className={"sticky"}>
                        <PageHeader
                            title={translate("page_title.work-order-create")}
                            removeBorder={true}
                            ButtonsLeading={[
                                {
                                    iconLeading: ArrowLeftIcon,
                                    className: "btn btn-header m-2",
                                    onClick: () => history.push('/work-orders/'),
                                    title: "Go back to work orders list"
                                }
                            ]}
                        />

                        <NavResponsive
                            addClass="px-4 sm:px-6 md:px-8 h-10"
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {currentTab.name === "WorkOrdersInfo" && (
                        <WorkOrdersCreateInfoTab
                            isCreate={true}
                            isLoading={isLoading}
                            getResourcePath={currentTab.resource}
                            translate={translate}
                            history={history}
                            handleBasicInputChange={handleBasicInputChange}
                            fieldsBasic={fieldsBasic}
                            fieldsComplaint={fieldsComplaint}
                            handleComplaintChange={handleComplaintChange}
                            handleAssignedChange={handleAssignedChange}
                            fieldsAssigned={fieldsAssigned}
                            handleAssetsChange={handleAssetsChange}
                            fieldsAssets={fieldsAssets}
                            layoutRef={layoutRef}
                            isLayoutScrolled={isLayoutScrolled}
                            scrollToTop={handleScrollLayoutOnTop}
                        />
                    )}

                    {currentTab.name === "Images" && (
                        <WorkOrdersCreateImagesTab
                            dataImages={dataImages}
                            dispatch={dispatch}
                            isLoading={isLoading || documentIsLoading || downloadIsLoading}
                            translate={translate}
                            updateDataImages={(items) => setDataImages(prev => prev.concat(items))}
                            deleteDataImage={(items) => setDataImages(prev => prev.filter((item) => item.preview !== items.preview))}
                        />
                    )}

                    {currentTab.name === "WorkOrdersDocument" && (
                        <CreateDocumentsTab
                            dataDocuments={dataDocuments}
                            resourcePath={currentTab.resource}
                            primaryField={"WorkOrderID"}
                            dispatch={dispatch}
                            isLoading={isLoading}
                            updateDataDocuments={(items) => setDataDocuments(prev => prev.concat(items))}
                            deleteDataDocuments={(items) => setDataDocuments(prev => prev.filter((item) => item.preview !== items.preview))}
                            translate={translate}
                        />
                    )}

                    <ModalCreateSuccessNew
                        resource={resource}
                        position={"center"}
                        successText={(createdResourceAutoCounter) => "Work order #" + createdResourceAutoCounter + " created successfully!"}
                        dispatch={dispatch}
                        isItemCreatedRef={isItemCreatedRef}

                        buttons={(createdResourceID, closeSuccessDialog, createdResourceAutoCounter) => [
                            {
                                label: translate("btn.create_new_work_order"),
                                onClick: closeSuccessDialog
                            },
                            {
                                label: translate("btn.edit_work_order", [createdResourceAutoCounter]),
                                onClick: () => history.push("/work-orders/" + createdResourceID)
                            },
                            {
                                label: translate("btn.to_labor_and_parts", [createdResourceAutoCounter]),
                                onClick: () => history.push("/work-orders/" + createdResourceID + '?parts')
                            },
                            {
                                label: translate("btn.go_back_to_work_orders_list"),
                                onClick: () => history.push("/work-orders/")
                            }
                        ]}
                        translate={translate}
                    />

                    <ModalDefault
                        title={translate("title.work_orders_for_truck", [selectedItem?.Truck])}
                        show={showTruckWoDialog}
                        onClose={handleTruckWoClick}
                        translate={translate}
                        widthClass={"max-w-6xl"}
                    >
                        <div className="max-h-[calc(100vh-14rem)] overflow-auto">
                            <ResourceTableTopBar
                                addClass="rounded-t-md ring-1 ring-black ring-opacity-5 px-4"
                            >
                                <FieldSearch
                                    addClass="form-control"
                                    name="query"
                                    value={queryFields?.query?.value}
                                    onChange={handleQueryChange}
                                    translate={translate}
                                />

                            </ResourceTableTopBar>

                            {(secondIsLoading || !!secondCount) && (
                                <ResourceTable
                                    data={secondData}
                                    fields={fields}
                                    queryFields={queryFields}

                                    options={{
                                        style: {
                                            stripedRows: true,
                                            horizontalLines: true,
                                            verticalLines: true,
                                            floatingActions: true
                                        }
                                    }}

                                    translate={translate}
                                    isLoading={secondIsLoading}
                                />

                            )}

                            {!secondIsLoading && !secondCount && (
                                <NoRecords
                                    addClass="p-10"
                                    title={translate("text.no_work_orders_for_truck")}
                                />
                            )}
                        </div>
                        <TableCardFooter
                            show={!(!secondData.length && !secondIsLoading)}
                        >
                            <Pagination
                                count={secondCount}
                                isLoading={secondIsLoading}
                                handleQueryChange={handleQueryChange}
                                queryFields={queryFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </ModalDefault>

                    <ModalDefault
                        title={translate("title.work_orders_for_trailer", [selectedItem?.Trailer])}
                        show={showTrailerWoDialog}
                        onClose={handleTrailerWoClick}
                        translate={translate}
                        widthClass={"max-w-6xl"}
                    >
                        <ResourceTableTopBar
                            addClass="rounded-t-md ring-1 ring-black ring-opacity-5"
                        >
                            <FieldSearch
                                addClass="form-control"
                                name="query"
                                value={queryFields?.query?.value}
                                onChange={handleQueryChange}
                                translate={translate}
                            />

                        </ResourceTableTopBar>

                        {(secondIsLoading || !!secondCount) && (
                            <ResourceTable
                                data={secondData}
                                fields={fields}
                                queryFields={queryFields}

                                options={{
                                    style: {
                                        stripedRows: true,
                                        horizontalLines: true,
                                        verticalLines: true,
                                        floatingActions: true
                                    }
                                }}

                                translate={translate}
                                isLoading={secondIsLoading}
                            />
                        )}

                        {!secondIsLoading && !secondCount && (
                            <NoRecords
                                addClass="p-10"
                                title={translate("text.no_work_orders_for_trailer")}
                            />
                        )}

                        <TableCardFooter
                            show={!(!secondData.length && !secondIsLoading)}
                        >
                            <Pagination
                                count={secondCount}
                                isLoading={secondIsLoading}
                                handleQueryChange={handleQueryChange}
                                queryFields={queryFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                    </ModalDefault>
                </div>
            </PageTabWithFooter>
        </Layout>
    )
}
