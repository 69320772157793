import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkPerm, getProp} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {getResource} from "../../../data/actions/resource";
import {getUser} from "../../../common/util/util-auth";
import RentalsEditInfoTab from "./rentals-edit-info-tab";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import RentalsBillingTab from "./rentals-billing-tab";
import {useLocation} from "react-router-dom";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import ModalCreateSuccessNew from "../../../common/components/modal/create-success-modal-new";
import {UPDATE_PERM} from "../../../util/util-constants";
import DocumentsList from "../../../common/components/tabs/documents-list";
import {Field} from "../../../data/services/fields";

export default function RentalsSingleView({history, match, translate}) {

    /** Constants
     ================================================================= */
    const ID = match.params.id;

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);
    const data = getProp(resource, "data", {});

    const location = useLocation();
    const isCopyMode = location.pathname.includes('copy')

    const [isLayoutScrolled, setIsLayoutScrolled] = useState(false)
    const [rentalInfo, setRentalInfo] = useState({})
    const layoutRef = useRef();
    let isItemCreatedRef = useRef(false);

    const uploadDocumentButtonRef = useRef();

    /** Helpers
     ================================================================= */
    const getTabs = () => {
        return [
            {
                name: 'Info',
                resource: Resources.RentalInfo,
                current: true,
                visible: true
            },
            {
                name: 'Billing',
                resource: Resources.WorkOrdersImages,
                current: false,
                visible: !isCopyMode
            },
            {
                name: 'Documents',
                resource: Resources.RentalDocuments,
                current: false,
                visible: !isCopyMode
            }
        ]
    }

    const [tabs, setTabs] = useState(getTabs(ID));
    const currentTab = tabs.find(it => it.current);

    /** Data events
     ================================================================= */
    const fetchData = (dispatch, resourcePath, ID) => {
        dispatch(getResource({
            user: getUser(),
            query: {
                RentalID: ID
            },
            resource: resourcePath,
        }))
    }

    /** UI Events
     ================================================================= */
    const handleTabChange = (resource) => {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const handleLayoutScroll = () => {
        if (layoutRef?.current.scrollTop > 100 && !isLayoutScrolled) {
            setIsLayoutScrolled(true)
        }

        if (layoutRef?.current.scrollTop <= 100 && isLayoutScrolled) {
            setIsLayoutScrolled(false)
        }
    }

    const handleScrollLayoutOnTop = () => {
        if (layoutRef?.current) {
            layoutRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        if (!isLoading && currentTab.resource === Resources.RentalInfo) {
            setRentalInfo(data)
        }
    }, [isLoading])

    /** Page Body
     ================================================================= */
    return (
        <Layout
            history={history}
            match={match}
            translate={translate}
            layoutRef={layoutRef}
            onScroll={handleLayoutScroll}
        >
            <PageHeader
                title={(!isCopyMode ? translate("page_title.rental") + " - " : translate("page_title.copy_rental") + " : ") + (rentalInfo.AutoCounter ?? '')}
                isLoading={isLoading}
                removeBorder={true}
                buttons={[{
                    className: "btn btn-primary",
                    label: "Upload document",
                    onClick: () => uploadDocumentButtonRef?.current && uploadDocumentButtonRef.current.click()
                }]}
                ButtonsLeading={[
                    {
                        iconLeading: ArrowLeftIcon,
                        className: "btn btn-header m-2",
                        onClick: () => history.push('/rentals/'),
                        title: "Go back to rentals"
                    }
                ]}
            />

            <NavResponsive
                addClass="px-4 sm:px-6 md:px-8 h-9 z-40"
                tabs={tabs}
                onTabChange={(!isLoading || currentTab?.name !== 'Info') && handleTabChange}
                translate={translate}
            />

            {currentTab.name === "Info" && (
                <RentalsEditInfoTab
                    data={data}
                    isLoading={isLoading}
                    dispatch={dispatch}
                    getResourcePath={currentTab.resource}
                    fetchData={() => fetchData(dispatch, currentTab.resource, ID)}
                    translate={translate}
                    history={history}
                    isLayoutScrolled={isLayoutScrolled}
                    scrollToTop={handleScrollLayoutOnTop}
                    isCopyMode={isCopyMode}
                    triggerItemCreatedModal={() => isItemCreatedRef.current = true}
                />
            )}

            {currentTab.name === "Billing" && (
                <RentalsBillingTab
                    data={data}
                    isLoading={isLoading}
                    translate={translate}
                    history={history}
                    match={match}
                    rentalInfo={rentalInfo}
                />
            )}

            {currentTab.name === "Documents" && (
                <div className={"mt-5"}>
                    <DocumentsList
                        id={ID}
                        uploadDocumentButtonRef={uploadDocumentButtonRef}
                        primarySortKey={"DocumentName"}
                        deleteDocumentKey={"DocumentID"}
                        disableEdit={true}
                        haveUpdatePermission={checkPerm(Resources.RentalDocumentsSingle, UPDATE_PERM)}
                        documentsResource={Resources.RentalDocuments}
                        downloadDocumentResource={Resources.RentalDocumentsSingle}
                        locationPath={"work-orders-documents-tab"}
                        primaryDocumentKey={"DocumentID"}
                        getDocumentUploadFields={(item) => {
                            return {
                                "RentalID": new Field('RentalID', ID, ['empty'], false, 'hidden'),
                                "Description": new Field('Description', item?.Description ?? "", ['empty'], false, 'text', {addContainerClass: "col-span-full"})
                            }
                        }}
                        getDocumentTableFields={() => {
                            return {
                                "DocumentName": new Field('DocumentName', '', [''], false),
                                "Description": new Field('Description', '', [''], false),
                                "CreateUpdateDate": new Field('CreateUpdateDate', '', [''], false, 'datetimezone'),
                                "UploadedBy": new Field('UploadedBy', '', [''], false, 'custom', {
                                    render: (item) => {
                                        return (<div>{item.FirstName} {item.LastName}</div>)
                                    },
                                    omitSort: true
                                })
                            }
                        }}
                        onGetQueryFields={() => {
                            return {
                                // "searchFields": new Field('searchFields', JSON.stringify({RentalID: ID}), [''], false, 'hidden'),
                                "id": new Field('id', ID, [''], false, 'hidden'),
                            }
                        }}
                        translate={translate}
                    />
                </div>


            )}

            <ModalCreateSuccessNew
                resource={resource}
                position={"center"}
                successText={(createdResourceAutoCounter) => "Rental #" + createdResourceAutoCounter + " created successfully!"}
                dispatch={dispatch}
                translate={translate}
                isItemCreatedRef={isItemCreatedRef}

                buttons={(createdResourceID, closeSuccessDialog, createdResourceAutoCounter) => [
                    {
                        label: translate("btn.create_new_rental"),
                        onClick: closeSuccessDialog
                    },
                    {
                        label: translate("btn.edit_rental", [createdResourceAutoCounter]),
                        onClick: () => history.push("/rentals/" + createdResourceID)
                    },
                    {
                        label: translate("btn.go_back_to_rentals_list"),
                        onClick: () => history.push("/rentals/")
                    }
                ]}
            />
        </Layout>
    )
}




