import {LoaderLarge} from "../../../../../common/components/loader";
import ChatbotBox from "../../../../../common/components/layout/layout-components/AIChat/chatbot-box";
import React, {useState} from "react";
import InfoBar from "../../../../../common/components/info-paragraph/info-bar";
import QuestionMarkCircleIcon from "@heroicons/react/24/outline/QuestionMarkCircleIcon";
import FieldTextarea from "../../../../../common/components/fields/field-textarea";
import FieldContainer from "../../../../../common/components/fields/field-container";
import Button from "../../../../../common/components/button";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

export default function WorkOrdersLaborPartsAiModal({aiLoading, aiData, translate, additionalAiInfo, setAdditionalAiInfo}) {
    const [areActionItemsVisible, setAreActionsItemsVisible] = useState(false);
    const [areSuggestedQuestionsVisible, setAreSuggestedQuestionsVisible] = useState(false);

    return (
        <>
            {aiLoading && (
                <>
                    <div className="h-64"/>
                    <LoaderLarge addClass={""}/>
                </>
            )}

            {!aiLoading && (
                <div className="p-6 text-base space-y-4">
                    <div className="">
                        <ChatbotBox
                            fakeStreamSpeed={20}
                            streamContent={true}
                            content={"Based on the initial complaint, here is what I would recommend following actions:"}
                            onEndStream={() => setAreActionsItemsVisible(true)}
                        />
                    </div>

                    {areActionItemsVisible && (<>
                            {aiData?.ActionItems.map(it => {
                                return (
                                    <>
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-6 flex flex-col">
                                                <div>Complaint</div>
                                                <div className="form-control flex-1 p-4">{it.Complaint}</div>
                                            </div>

                                            <div className="col-span-6 flex flex-col">
                                                <div>Correction</div>
                                                <div className="form-control flex-1 p-4">{it.Correction}</div>
                                            </div>
                                        </div>

                                        <div className="overflow-hidden rounded-btn border border-tm-gray-200">
                                            <table className="table-auto w-[calc(100%+2px)] -m-px">
                                                <thead>
                                                <tr className="bg-tm-gray-50">
                                                    <th className="h-9 px-6 border border-tm-gray-200">Description</th>
                                                    <th className="h-9 px-6 border border-tm-gray-200">Qty</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {it?.Labor?.map(iit => {
                                                    return (
                                                        <tr className="bg-inverse">
                                                            <td className="h-9 px-6 border border-tm-gray-200">{iit.LaborDescription}</td>
                                                            <td className="h-9 px-6 border border-tm-gray-200">{iit.LaborHours}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )
                            })}

                            <div className="pt-10">
                                <ChatbotBox
                                    fakeStreamSpeed={20}
                                    streamContent={true}
                                    content={"Also, here are some of the additional questions that you could ask a customer?"}
                                    onEndStream={() => setAreSuggestedQuestionsVisible(true)}
                                />
                            </div>
                        </>
                    )}

                    {areSuggestedQuestionsVisible && (
                        <>
                            <ul className="pl-4">
                                {aiData?.SuggestedQuestions.map(it => {
                                    return (
                                        <li className="list-disc text-tm-gray-600 p-1 italic">
                                            {it}
                                        </li>
                                    )
                                })}
                            </ul>

                            <InfoBar Icon={QuestionMarkCircleIcon} iconClass="w-6 h-6 shrink-0 text-sky-600">
                                <ChatbotBox
                                    fakeStreamSpeed={20}
                                    streamContent={true}
                                    content={"Would you like to add more info. Answer of one, or more of these questions and click \"Ask again\" button?"}
                                />
                            </InfoBar>
                        </>
                    )}

                    <div className="flex gap-4 pt-5">
                        <div className="flex-1">
                            <FieldContainer
                                label=""
                                translate={translate}
                            >
                                <FieldTextarea
                                    addClass="form-control pt-2"
                                    rows="3"
                                    value={additionalAiInfo ?? ""}
                                    name={"AdditionalAiInfo"}
                                    onChange={(_, value) => setAdditionalAiInfo(value)}
                                />
                            </FieldContainer>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}