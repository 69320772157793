import React, {useEffect, useRef, useState} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import InformationCircleIcon from "@heroicons/react/20/solid/esm/InformationCircleIcon";
import {showGlobalModal, showModal} from "../../../../data/actions/ui";
import Resources from "../../../../data/services/resources";
import {checkPerm, getProp, groupListBySCAC} from "../../../../common/util/util-helpers";
import {createResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../common/util/localStorage";

import InfoParagraph from "../../../../common/components/info-paragraph";
import Buttons from "../../../../common/components/buttons";
import {ArrowLeftIcon, MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import {
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    READ_PERM,
    RENTAL_STATUS_BOOKING_REQUESTED
} from "../../../../common/util/util-consts";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import PageTabWithFooter from "../../../../common/components/layout/layout-components/page/page-tab-with-footer";
import ProgressArrows from "../../../../common/components/progress-arrows/progress-arrows-rental";
import FieldOptions from "../../../../common/components/fields/field-options";
import FormCard from "../../../../common/components/forms/form-card";
import {RENT_STATUS_RENTED} from "../../../../common/util/util-consts";
import {getSecondResource} from "../../../../data/actions/secondResource";
import {useSelector} from "react-redux";


const RentalsEditInfoTab = ({
                                dispatch,
                                data,
                                fetchData,
                                history,
                                isLoading,
                                translate,
                                isLayoutScrolled,
                                scrollToTop,
                                isCopyMode,
                                triggerItemCreatedModal
                            }) => {

    const secondResource = useSelector(state => state.secondResource)

    /** Helpers
     ================================================================= */
    const basicInfoRef = useRef();
    const assetsRef = useRef();

    const [rentalsSideMenu, setRentalsSideMenu] = useState(
        [
            {
                key: "basicInfo",
                label: translate('text.basicInfo'),
                ref: basicInfoRef,
                expanded: true
            },
            {
                key: "assets",
                label: translate('text.assets'),
                ref: assetsRef,
                expanded: true
            },
        ])

    const getRentalID = () => {
        return data.RentalID
    }

    const getBasicFields = (item = {}) => {
        const fieldTemplates = {
            RentTypeID: new Field('RentTypeID', '', ['required'], false, 'select', {addContainerClass: "col-span-6"}),
            RefNumber: new Field('RefNumber', '', [''], false, 'text', {addContainerClass: "col-span-6"}),
            RentTo: new Field('RentTo', !!item.RentOrganizationID ? 2 : 1, [''], false, 'button-group', {
                data: {1: translate('btn.contact'), 2: translate('btn.organization')},
                addContainerClass: 'col-span-full'
            }),
            RentContactID: new Field('RentContactID', '', [''], !item.RentContactID && item.RentOrganizationID, 'select-search',
                {
                    addContainerClass: "col-start-1 col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleContactInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            RentOrganizationID: new Field('RentOrganizationID', '', [''], !item.RentOrganizationID, 'select-search',
                {
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => (checkPerm(Resources.OrganizationsQuick, READ_PERM)) && handleOrganizationInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            PayTo: new Field('PayTo', !!item.PayOrganizationID ? 2 : 1, [''], false, 'button-group', {
                label: "ChargeTo",
                data: {1: translate('btn.contact'), 2: translate('btn.organization')},
                addContainerClass: 'col-span-full'
            }),
            PayContactID: new Field('PayContactID', '', [''], !item.PayContactID && item.PayOrganizationID, 'select-search',
                {
                    addContainerClass: "col-start-1 col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleContactInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            PayOrganizationID: new Field('PayOrganizationID', '', [''], !item.PayOrganizationID, 'select-search',
                {
                    addContainerClass: "col-span-6",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[{
                                    icon: InformationCircleIcon,
                                    onClick: () => handleOrganizationInfoClick(it?.value?.value),
                                    isVisible: !!it?.value
                                }]}
                            />
                        )
                    }
                },
            ),
            RentalContractID: new Field('RentalContractID', '', [''], false, 'select-search', {addContainerClass: 'col-span-full'}),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search',
                {
                    addContainerClass: "col-span-6",
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed'
                }
            ),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search',
                {
                    addContainerClass: "col-span-6",
                }, {
                    hasPortal: true,
                    menuPosition: 'fixed'
                }
            ),
            InternalNotes: new Field("InternalNotes", '', [''], false, "textarea", {addContainerClass: "col-start-1 col-span-6"}),
            ExternalNotesCustomer: new Field("ExternalNotesCustomer", '', [''], false, "textarea", {addContainerClass: "col-span-6"})
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getAssetFields = (item = {}) => {
        const fieldTemplates = {
            AssetType: new Field('AssetType', !!item.TrailerID ? 2 : 1, [''], false, 'button-group', {
                data: {1: translate('btn.truck'), 2: translate('btn.trailer')},
                addContainerClass: 'col-span-full'
            }),
            TruckID: new Field(
                'TruckID',
                '',
                [''],
                !item.TruckID && item.TrailerID,
                'select-search',
                {
                    addContainerClass: 'col-span-6',
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    /*{
                                        icon: ListBulletIcon,
                                        onClick: () => handleTruckWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },*/
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTruckInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }
                                ]}
                            />
                        )
                    }
                },
                {}
            ),
            TrailerID: new Field(
                'TrailerID',
                '',
                [''],
                !item.TrailerID,
                'select-search',
                {
                    addContainerClass: 'col-span-6',
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    /*{
                                        icon: ListBulletIcon,
                                        onClick: () => handleTrailerWoClick(it?.value?.metadata),
                                        isVisible: !!it?.value?.metadata
                                    },*/
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => handleTrailerInfoClick(it?.value?.value),
                                        isVisible: !!it?.value
                                    }]}
                            />
                        )
                    }
                },
            ),
        }
        let filledFields = fillFieldsFromData(fieldTemplates, item)

        if (!!filledFields.TruckID.value) {
            filledFields.TruckID.value.metadata = item.TruckInfo;
        }

        if (!!filledFields.TrailerID.value) {
            filledFields.TrailerID.value.metadata = item.TrailerInfo;
        }
        return filledFields
    }

    const updateRentalStatusID = (statusID) => {
        setRentalStatusID(statusID)
        setIsStateDirty(true)
    }

    /** UI Events
     ================================================================= */
    const handleScrollToSectionClick = (sectionRef) => {
        sectionRef.current.scrollIntoView({block: "center", behavior: "smooth"})
    }

    const handleContactInfoClick = (contactID) => {
        dispatch(showModal("ViewContactCard", {ContactID: contactID}))
    }

    const handleOrganizationInfoClick = (organizationID) => {
        dispatch(showModal("ViewCustomerCard", {OrganizationID: organizationID}))
    }

    const handleTruckInfoClick = (truckID) => {
        dispatch(showGlobalModal("ViewTruckCard", truckID))
    }

    const handleTrailerInfoClick = (trailerID) => {
        dispatch(showGlobalModal("ViewTrailerCard", trailerID))
    }

    const handleBasicInputChange = (name, value) => {
        let fieldsBasicUpdate = Object.assign({}, fieldsBasic);
        fieldsBasicUpdate = FieldsManager.updateField(fieldsBasicUpdate, name, value);

        if (name === "RentTo") {
            fieldsBasicUpdate.RentContactID.disabled = value === 2
            fieldsBasicUpdate.RentContactID.value = value === 1 ? fieldsBasicUpdate.RentContactID.value : "";
            /*fieldsBasicUpdate.RentContactID.validate = value === 1 ? ['required'] : ['']*/

            fieldsBasicUpdate.RentOrganizationID.disabled = value === 1
            fieldsBasicUpdate.RentOrganizationID.value = value === 2 ? fieldsBasicUpdate.RentOrganizationID.value : "";
            /*fieldsBasicUpdate.RentOrganizationID.validate = value === 2 ? ['required'] : ['']*/
        }

        if (name === "PayTo") {
            fieldsBasicUpdate.PayContactID.disabled = value === 2
            fieldsBasicUpdate.PayContactID.value = value === 1 ? fieldsBasicUpdate.PayContactID.value : "";
            /*fieldsBasicUpdate.PayContactID.validate = value === 1 ? ['required'] : ['']*/

            fieldsBasicUpdate.PayOrganizationID.disabled = value === 1
            fieldsBasicUpdate.PayOrganizationID.value = value === 2 ? fieldsBasicUpdate.PayOrganizationID.value : "";
            /*fieldsBasicUpdate.PayOrganizationID.validate = value === 2 ? ['required'] : ['']*/
        }

        setFieldsBasic(fieldsBasicUpdate);
        setIsStateDirty(true);
    }

    const handleAssetsInputChange = (name, value) => {
        let fieldsAssetsUpdate = Object.assign({}, fieldsAssets);
        fieldsAssetsUpdate = FieldsManager.updateField(fieldsAssetsUpdate, name, value);

        if (name === "AssetType") {
            if (fieldsAssetsUpdate['TruckID'].value && value === 2) setExistingRentalWarning(false)
            if (fieldsAssetsUpdate['TrailerID'].value && value === 1) setExistingRentalWarning(false)

            fieldsAssetsUpdate.TruckID.disabled = value === 2
            fieldsAssetsUpdate.TruckID.value = value === 1 ? fieldsAssetsUpdate.TruckID.value : "";
            /*fieldsAssetsUpdate.TruckID.validate = value === 1 ? ['required'] : ['']*/

            fieldsAssetsUpdate.TrailerID.disabled = value === 1
            fieldsAssetsUpdate.TrailerID.value = value === 2 ? fieldsAssetsUpdate.TrailerID.value : "";
            /*fieldsAssetsUpdate.TrailerID.validate = value === 2 ? ['required'] : ['']*/
        }

        if (name === "TruckID" || name === "TrailerID") {
            const isAssetInActiveRent = value.metadata.RentStatusID === RENT_STATUS_RENTED
            setExistingRentalWarning(isAssetInActiveRent)
            if (isAssetInActiveRent) setExistingRentalID(value.metadata.RentalID)
        }

        setFieldsAssets(fieldsAssetsUpdate);
        setIsStateDirty(true);
    }

    const toggleExpandSection = (sectionName) => {
        let sections = Object.values(rentalsSideMenu)

        sections.map(it => {
            if (sectionName === it.key) {
                it.expanded = !it.expanded;
            }
            return it;
        })

        setRentalsSideMenu(sections)
    }

    /** State
     ================================================================= */
    const [fieldsAssets, setFieldsAssets] = useState(getAssetFields());
    const [fieldsBasic, setFieldsBasic] = useState(getBasicFields());
    const [isStateDirty, setIsStateDirty] = useState(false);
    const [rentalStatusID, setRentalStatusID] = useState(RENTAL_STATUS_BOOKING_REQUESTED)
    const [initialRentalStatusID, setInitialRentalStatusID] = useState(RENTAL_STATUS_BOOKING_REQUESTED)
    const [existingRentalWarning, setExistingRentalWarning] = useState(false)
    const [existingRentalID, setExistingRentalID] = useState(null)
    const [isInitialCheck, setIsInitialCheck] = useState(true)

    /** Life cycle
     ================================================================= */
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (!isLoading) {
            setFieldsBasic(getBasicFields(data));
            setFieldsAssets(getAssetFields(data));
            setRentalStatusID(getProp(data, "RentalStatusID", 1))
            setInitialRentalStatusID(getProp(data, "RentalStatusID", 1))
            setExistingRentalWarning(false)
        }
    }, [isLoading])

    useEffect(() => {
        if (secondResource.data?.RentStatusID === RENT_STATUS_RENTED) {
            setExistingRentalWarning(true)
            setExistingRentalID(secondResource.data.RentalID)
        }
    }, [secondResource])

    useEffect(() => {
        if (isInitialCheck) {
            if (fieldsAssets['TruckID']?.value) {
                fetchTruckTrailerData(Resources.TrucksInfo, fieldsAssets['TruckID'].value.value)
                setIsInitialCheck(false)
            } else if (fieldsAssets['TrailerID']?.value) {
                fetchTruckTrailerData(Resources.Trailer, fieldsAssets['TrailerID'].value.value)
                setIsInitialCheck(false)
            }
        }
    }, [fieldsAssets])

    /** Data events
     ================================================================= */
    const fetchTruckTrailerData = (resource, id) => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: resource,
            query: {id: id}
        }))
    }

    const onSubmitClick = () => {
        const fieldsBasicValidate = FieldsManager.validateFields(fieldsBasic);
        const fieldsAssetsValidate = FieldsManager.validateFields(fieldsAssets);

        if (
            FieldsManager.checkFieldsForErrors(fieldsBasicValidate) &&
            FieldsManager.checkFieldsForErrors(fieldsAssetsValidate)
        ) {
            let params = Object.assign({},
                FieldsManager.getFieldKeyValues(fieldsBasicValidate),
                FieldsManager.getFieldKeyValues(fieldsAssetsValidate),
            )

            params.RentalStatusID = rentalStatusID
            if (isCopyMode) {
                dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: params,
                    customID: 'RentalID',
                    query: {},
                    resource: Resources.Rental,
                }))
                triggerItemCreatedModal()
            } else {
                dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, params, {RentalID: getRentalID()}),
                    query: {
                        RentalID: getRentalID()
                    },
                    successMessage: translate("message.rental_updated"),
                    errorMessage: true,
                    resource: Resources.RentalInfo,
                    piggyResource: Resources.RentalInfo
                }))
            }
        } else {
            setFieldsBasic(fieldsBasicValidate)
            setFieldsAssets(fieldsAssetsValidate)
        }
    }

    /** Render
     ================================================================= */
    const isDifferentStatusSelected = rentalStatusID !== initialRentalStatusID

    return (
        <PageTabWithFooter
            isLoading={isLoading}
            buttons={
                [
                    {
                        className: "btn btn-primary",
                        onClick: onSubmitClick,
                        disabled: !isCopyMode && !isStateDirty,
                        label: translate("btn.save")
                    },
                    {
                        className: "btn btn-outline",
                        disabled: !isStateDirty,
                        onClick: fetchData,
                        label: translate("btn.cancel")
                    },
                ]
            }
        >
            <div className="sticky h-10 top-52 w-44">
                {isLayoutScrolled && (
                    <div className="flex ml-4">
                        <div>
                            <Buttons
                                buttons={[
                                    {
                                        iconLeading: ArrowLeftIcon,
                                        className: "btn btn-header",
                                        onClick: () => history.push('/rentals/'),
                                        title: "Go back to rentals list"
                                    }
                                ]}
                            />
                        </div>

                        <button
                            onClick={() => scrollToTop()}
                            className="btn btn-text ml-2 hover:bg-primary-transparent hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                        >
                            {data.AutoCounter ?? translate("text.scroll_to_top")}
                        </button>
                    </div>
                )}

                {rentalsSideMenu.map(it => {
                    return (<div className={"flex ml-4"}>
                            <button
                                className="flex btn-icon rounded-button hover:bg-tm-gray-200"
                                onClick={() => toggleExpandSection(it.key)}
                            >
                                {!it.expanded && (
                                    <PlusIcon className="w-5 h-5 text-green-600"/>
                                )}

                                {it.expanded && (
                                    <MinusIcon className="w-5 h-5"/>
                                )}
                            </button>

                            <button
                                onClick={() => handleScrollToSectionClick(it.ref)}
                                className="text-tm-gray-800 hover:bg-inverse flex items-center px-3 py-2 text-sm font-medium rounded-btn w-full"
                            >
                                <span className="truncate">{it.label}</span>
                            </button>
                        </div>
                    )
                })}
            </div>

            <div className="xl:max-w-7xl my-5 mx-auto ml-44">
                <ProgressArrows
                    RentalStatusID={rentalStatusID}
                    locked={false}
                    initialRentalStatusID={initialRentalStatusID}
                    onItemClick={updateRentalStatusID}
                    translate={translate}
                />

                {isDifferentStatusSelected && (
                    <InfoParagraph className={"mt-4 max-w-2xl"}>
                        {translate('info.rental_status_is_changed')}
                    </InfoParagraph>
                )}
            </div>
            <div className="3xl:max-w-7xl py-8 space-y-6 mb-64 grid grid-cols-12 mx-auto ml-44">
                <div className={"xl:max-w-7xl space-y-8 col-span-12"}>
                    <FormCard
                        innerRef={basicInfoRef}
                        title={translate('text.basicInfo')}
                        onInputChange={handleBasicInputChange}
                        fields={fieldsBasic}
                        selects={getSelects()}
                        addFieldContainerClass={"col-span-6"}
                        translate={translate}
                        isExpanded={rentalsSideMenu[0].expanded}
                        toggleExpandSection={() => toggleExpandSection('basicInfo')}
                    />

                    <FormCard
                        innerRef={assetsRef}
                        title={translate('text.assets')}
                        onInputChange={handleAssetsInputChange}
                        fields={fieldsAssets}
                        selects={getSelects()}
                        addFieldContainerClass={"col-span-6"}
                        translate={translate}
                        isExpanded={rentalsSideMenu[1].expanded}
                        toggleExpandSection={() => toggleExpandSection('assets')}
                        contentBefore={existingRentalWarning ? (
                            <a href={`${existingRentalID}`} target="_blank">
                                <InfoParagraph
                                   className={"max-w-fit"}
                                   type="danger"
                                   message={
                                       <p>{translate("text.existingRentalWarning")}
                                       </p>
                                   }
                                />
                            </a>
                        ) : null}
                    />
                </div>
            </div>
        </PageTabWithFooter>
    )
}

function getSelects() {
    return {
        RentTypeID: {
            1: 'Rent',
            2: 'Lease'
        },
        PayContactID: {
            api: 'api/' + Resources.Contacts,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
                metadata: it
            })
        },
        PayOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.OrganizationID,
                metadata: it
            })
        },
        RentContactID: {
            api: 'api/' + Resources.ContactsQuick,
            query: {},
            searchMap: (it) => ({
                label: `${it.FirstName} ${it.LastName}`,
                value: it.ContactID,
                metadata: it
            })
        },
        RentOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: {},
            searchMap: (it) => ({
                label: it.LegalName,
                value: it.OrganizationID,
                metadata: it
            })
        },
        TruckID: {
            api: 'api/' + Resources.TrucksQuick,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Truck')
            }
        },
        TrailerID: {
            api: 'api/' + Resources.TrailersQuick,
            query: {
                NotSoldRetired: 1
            },
            customizeList: (list) => {
                return groupListBySCAC(list, 'Trailer')
            }
        },
        RentalContractID: {
            api: 'api/' + Resources.WorkOrdersRentalContracts,
            query: {},
            searchMap: (it) => ({
                label: it.RefNumber,
                value: it.RentalContractID,
                metadata: it
            })
        },
        OfficeID: {
            api: 'api/' + Resources.OfficesQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.OfficeName,
                value: item.OfficeID
            })
        },
        ContactGroupID: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
    }
}

export default RentalsEditInfoTab;
