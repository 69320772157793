import { useState } from "react";
import LocalStorage from "../util/localStorage";
import { fillFieldsFromData } from "../common/util/util-fields";
import { FieldsManager } from "../data/services/fields";

// set default query if no query

export default function useQuery(defaultFields, path) {
    const savedQuery = getSavedQuery(defaultFields, path);

    const [query, setQuery] = useState(!!savedQuery ? savedQuery : defaultFields)

    function saveQuery(query) {
        saveCurrentQuery(query, path)

        setQuery(() => query);
    }

    return [query, saveQuery];
}

const getSavedQuery = (defaultFields, path) => {
    const key = path + "_query";
    let storedQuery = LocalStorage.get(key) ?? {};
    return fillFieldsFromData(defaultFields, storedQuery);
}

const saveCurrentQuery = (query, path) => {
    const key = path + "_query";
    const queryToStore = FieldsManager.getFieldKeyValuesAndLabels(query);
    delete queryToStore.offset

    LocalStorage.set(key, queryToStore);
}